var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "p-content" },
    [
      _vm.features && _vm.features.length
        ? _c(
            "div",
            { staticClass: "p-content__section-l c-section" },
            _vm._l(_vm.features, function (item, index) {
              return _c("EventCard", { key: index, attrs: { content: item } })
            }),
            1
          )
        : _vm._e(),
      _vm.contents && _vm.contents.length
        ? _c(
            "div",
            { staticClass: "p-content__section-l c-section" },
            _vm._l(_vm.contents, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "u-mb24" },
                [_c("EventCard", { attrs: { content: item } })],
                1
              )
            }),
            0
          )
        : !_vm.$_loading_state
        ? _c("Empty", {
            attrs: {
              "icon-name": "none",
              text: "現在ご案内可能なイベントはありません",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }