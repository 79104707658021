<template>
  <div class="p-content">
    <div
      v-if="features && features.length"
      class="p-content__section-l c-section">
      <EventCard
        v-for="(item, index) in features"
        :key="index"
        :content="item" />
    </div>

    <div
      v-if="contents && contents.length"
      class="p-content__section-l c-section">
      <div class="u-mb24" v-for="(item, index) in contents" :key="index">
        <EventCard :content="item" />
      </div>
    </div>

    <Empty
      v-else-if="!$_loading_state"
      icon-name="none"
      text="現在ご案内可能なイベントはありません" />
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  onBeforeMount,
  onBeforeUnmount
} from '@vue/composition-api';
import { SystemDialogMessage } from '@/constants/enums';

export default defineComponent({
  setup: (props, context) => {
    const contents = ref([]);
    const features = ref(undefined);
    const nextPageUrl = ref(null);
    const scrolledToBottom = ref(false);

    const fetchFeaturesList = async () => {
      try {
        const { data } = await context.root
          .$repositories('contentJoinFeature')
          .get();
        features.value = data?.data;
      } catch {
        window.location.href = context.root.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      }
    };

    const fetchContentsList = async () => {
      try {
        const { data } = await context.root
          .$repositories('contentJoin')
          .get(context.root.$utilities.getUrlSearch(nextPageUrl.value));
        contents.value = contents.value.concat(data?.data);
        nextPageUrl.value = data.next_page_url;
      } catch {
        window.location.href = context.root.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      }
    };

    /**
     * 画面下部までスクロールしたときに次のセクション分のコンテンツを取得する
     */
    const scroll = () => {
      const isBottomOfWindow =
        Math.ceil(window.pageYOffset) + window.innerHeight >=
        document.documentElement.offsetHeight;

      if (isBottomOfWindow) {
        scrolledToBottom.value = true;
        loadMore();
      }
    };

    const loadMore = async () => {
      if (context.root.$_loading_state || !nextPageUrl.value) return;
      context.root.$_loading_start();
      await fetchContentsList();
      context.root.$_loading_stop();
    };

    /**
     * コンテンツ押下時の処理
     * @param {object} reward
     * @param {number} eventId
     */
    const onClickContentLink = (reward, eventId) => {
      const contentUrl = `coffee-creation-app://page/detail/?url=/content/join/detail/${eventId}`;
      window.location.href = contentUrl;
    };

    onBeforeMount(async () => {
      context.root.$_loading_start();
      await Promise.all([fetchFeaturesList(), fetchContentsList()]).then(() => {
        window.addEventListener('scroll', scroll);
        context.root.$_loading_stop();
      });
    });

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', scroll);
    });

    return {
      contents,
      features,
      onClickContentLink
    };
  }
});
</script>
